export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/about-us": [23],
		"/airdrop-guide": [25],
		"/airdrop": [24],
		"/dev/feed/logic": [26],
		"/dev/feed/watchhistory": [27],
		"/dev/sentry/errors": [28],
		"/faq": [29],
		"/faq/install-app": [30],
		"/(feed)/feed": [5,[2]],
		"/(feed)/feed/no-videos": [7,[2]],
		"/(feed)/feed/[id=videoId]": [6,[2]],
		"/(feed)/hotornot": [8,[2,3]],
		"/(feed)/hotornot/no-videos": [10,[2,3]],
		"/(feed)/hotornot/[id=videoId]": [9,[2,3]],
		"/(feed)/leaderboard": [11,[2]],
		"/(feed)/menu": [12,[2]],
		"/(feed)/menu/auth": [13,[2]],
		"/(feed)/menu/reauth": [14,[2]],
		"/migrate-faq": [33],
		"/migrate": [31],
		"/migrate/form": [32],
		"/(feed)/notifications": [15,[2]],
		"/privacy-policy": [34],
		"/profile": [35],
		"/profile/[id]": [36],
		"/profile/[id]/edit": [37],
		"/profile/[id]/lovers": [38],
		"/profile/[id]/loving": [39],
		"/profile/[id]/post": [40],
		"/profile/[id]/post/[postId]": [41],
		"/profile/[id]/speculations": [42],
		"/profile/[id]/speculations/[postId=videoId]": [43],
		"/refer-earn": [44],
		"/terms-of-service": [45],
		"/upload": [46],
		"/upload/new": [47],
		"/(feed)/users/[id]": [16,[2]],
		"/(feed)/waitlist": [17,[2]],
		"/(feed)/wallet-staging": [20,[2]],
		"/(feed)/wallet-staging/[id]": [21,[2]],
		"/(feed)/wallet-staging/[id]/send": [22,[2]],
		"/(feed)/wallet": [18,[2]],
		"/(feed)/wallet/transactions": [19,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';